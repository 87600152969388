console.log( 'hi form app.js' );
  
  $(document).ready(function(){
    
    // HOME > Слайдер
    $('.home-page-slider').slick({
      dots: true,
      slidesToShow: 1,
       arrows: false
      // centerMode: true,
    });

    // HOME > Моб. меню
    $('.menu__mob-btn').on('click', function() {
      $('.menu__item').fadeToggle();
    });


    //Header > модальное окно
      // показать
      $('[data-modal-id-btn]').on('click', function() {
        let id = $(this).attr('data-modal-id-btn');
        $(`[data-modal-id="${id}"]`).show();
      });

      // закрыть
      $('.modal__close-btn').on('click', function() {
        $(this).parents('.modal-overlay').hide();
      });


  });
